import { Blockquote } from 'flowbite-react';
import styled from 'styled-components';

const Container = styled.div`
    padding: 20px;
`;
function Guide() {
    return (
        <Container>
            <Blockquote className="mb-3">
                <p className="text-xl font-semibold italic text-gray-900 dark:text-white">
                    " Làm thế nào để tạo link tiếp thị liên kết? "
                </p>
            </Blockquote>
            <p className="mb-3 text-gray-500 dark:text-gray-400">
                Bước 1: Chọn sản phẩm bạn muốn mua copy đường dẫn của sản phẩm đó.
            </p>
            <img src="/guide1_1.jpg" className="mb-3" alt="Example 1" />
            <img src="/guide1_2.jpg" className="mb-3" alt="Example 1" />
            <p className="mb-3 text-gray-500 dark:text-gray-400">
                Bước 2: Dán đường dẫn vào ô link sản phẩm.
            </p>
            <p className="mb-3 text-gray-500 dark:text-gray-400">
                Bước 2.1: Điền số điện thoại và loại ví điện tử bạn muốn nhận tiền hoàn. Và click vào nút tạo link.
            </p>
            <img src="/guide2.png" className="mb-3" alt="Example 1" />
            <p className="mb-3 text-gray-500 dark:text-gray-400">
                Bước 2.2: Nếu bạn không có ví điện tử, hãy điền số tài khoản ngân hàng. Và click vào nút tạo link.
            </p>
            <img src="/guide2_2.png" className="mb-3" alt="Example 1" />
            <p className="mb-3 text-gray-500 dark:text-gray-400">
                Bước 3: Click vào link tiếp thị liên kết và mua hàng như bình thường.
            </p>

            <Blockquote className="mb-3">
                <p className="text-xl font-semibold italic text-gray-900 dark:text-white">
                    " Làm thế nào để biết trạng thái của đơn hoàn? "
                </p>
            </Blockquote>
            <p className="mb-3 text-gray-500 dark:text-gray-400">
                Sau khi bạn mua hàng, sau một ngày shopee sẽ xác nhận đơn hàng được tiếp thị, hệ thống sẽ tự tạo đơn hoàn cho bạn. Để tra cứu trạng thái đơn hoàn, bạn chỉ cần nhập số điện thoại.
            </p>
            <img src="/guide6.png" className="mb-3" alt="Example 1" />
            <p className="mb-3 text-gray-500 dark:text-gray-400">
                <p>Trạng thái shopee: Trạng thái của đơn trên shopee</p>
                <p>Trạng thái hoàn đơn:</p>
                <ul>
                    <li> - Chưa hoàn tiền</li>
                    <li> - Đã hoàn tiền</li>
                </ul>
            </p>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
        </Container>
    );
}

export default Guide;
