import { Navbar } from 'flowbite-react';
import { Link, NavLink } from 'react-router-dom';

function CustomNavLink({ to, children }: any) {
    return (
      <Link to={to}>
        <Navbar.Link as="div">
          {children}
        </Navbar.Link>
      </Link>
    );
  }

const Header = () => {
    return (
        <Navbar fluid rounded>
            <Navbar.Brand href="#">
                <img src="/logo.png" className="mr-3 h-6 sm:h-9" alt="Flowbite React Logo" />
                <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">Hoàn tiền tiếp thị liên kết</span>
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse>
                <CustomNavLink to="/" className="text-base font-medium text-gray-500 hover:text-gray-900">
                    Trang chủ
                </CustomNavLink>
                <CustomNavLink to="/guide" className="text-base font-medium text-gray-500 hover:text-gray-900">
                    Hướng dẫn
                </CustomNavLink>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default Header;