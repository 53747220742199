import { Alert, Banner } from "flowbite-react";
import { HiArrowRight, HiX } from "react-icons/hi";
import { MdPercent } from "react-icons/md";
import { HiInformationCircle } from "react-icons/hi";
import { Link } from "react-router-dom";
import styled from "styled-components";

const GuideLink = styled.h1`
  font-family: Satisfy, cursive;
  font-size: 3em;
  color: SandyBrown;
  text-shadow: 0.02em 0.02em 0 Brown, 0 0 0.5em violet;
`;
const ImageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  gap: 20px;
`;

function Announcement() {
  return (
    <>
      <ImageContainer>
        <a href="https://shope.ee/9pICtjWlHy">
          <img src="/ads.jpg" alt="ads" style={{ height: "150px" }} />
        </a>
        {/* <a href="https://shope.ee/VkXmbs50O">
          <img src="/ads2.png" alt="ads" style={{ height: "150px" }} />
        </a> */}
      </ImageContainer>
      {/* <Alert style={{ marginTop: "-25px", marginBottom: "-15px" }}>
        <span className="font-medium">
          Ngày 9 - 10/5 .Hoa hồng extra từ các shop:
          <ul>
            <li>&#9642; P&G Việt Nam: 10%</li>
            <li>&#9642; Olay - Gian Hàng Chính Hãng: 10%</li>
            <li>&#9642; P&G Giặt Xả Vải: 5%</li>
          </ul>
        </span>
      </Alert> */}
      <Banner>
        <div className="flex w-full justify-between border-t border-gray-200 bg-gray-50 dark:border-gray-600 dark:bg-gray-700">
          <div className="mx-auto flex items-center">
            <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
              <span className="mr-3 inline-flex h-6 w-6 items-center justify-center rounded-full bg-gray-200 p-1 dark:bg-gray-600">
                <MdPercent className="h-4 w-4" />
              </span>
              <span className="[&_p]:inline">
                Tiền hoàn đến từ đâu?&nbsp;
                <Link
                  to="./introduce"
                  className="ml-0 flex items-center text-sm font-medium text-cyan-600 hover:underline dark:text-cyan-500 md:ml-1 md:inline-flex"
                >
                  Tìm hiểu thêm
                  <HiArrowRight className="ml-2" />
                </Link>
              </span>
            </p>
          </div>
          <Banner.CollapseButton
            color="gray"
            className="border-0 bg-transparent text-gray-500 dark:text-gray-400"
          >
            <HiX className="h-4 w-4" />
          </Banner.CollapseButton>
        </div>
      </Banner>

      <Link
        to="./guide"
        className="ml-0 flex items-center text-sm font-medium text-cyan-600 hover:underline dark:text-cyan-500 md:ml-1 md:inline-flex"
      >
        <GuideLink>Hướng dẫn tạo link</GuideLink>
      </Link>
      <Alert color="info" icon={HiInformationCircle}>
        <span className="font-medium">
          Mua nhiều sản phẩm cùng 1 shop. Chỉ cần tạo link cho 1 sản phẩm.
        </span>
      </Alert>
    </>
  );
}

export default Announcement;
