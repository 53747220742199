import React, { useState } from 'react';
import { Button } from 'flowbite-react';
import styled from 'styled-components';
import GetLink from './GetLink';
import Transaction from './Transaction';
import Announcement from './Banner';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
`;

function Home() {
    const [openModal, setOpenModal] = useState(false);
    const [openTransactionModal, setTransactionModal] = useState(false);

    return (
        <div>
            <Container>
                <Announcement />
                <Button color="blue" onClick={() => setOpenModal(true)}>Tạo Link</Button>
                <Button color="success" onClick={() => setTransactionModal(true)}>Tra cứu đơn</Button>
            </Container>
            <GetLink openModal={openModal} setOpenModal={setOpenModal} />
            <Transaction openModal={openTransactionModal} setOpenModal={setTransactionModal} />
        </div>
    );
}

export default Home;
