import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom'; // Import Routes
import Home from './Home'; // Import the Home component
import Guide from './Guide'; // Assuming you have a Guide component
import Header from './Header';
import Introduce from './Introduce';
import FooterComponent from './Footer';

function App() {
  return (
    <div>
      <Header />
      <Routes> {/* Use Routes instead of Switch */}
        <Route path="/" element={<Home />} /> {/* Use element prop */}
        <Route path="/guide" element={<Guide />} /> {/* Use element prop */}
        <Route path="/introduce" element={<Introduce />} />
        {/* other routes */}
      </Routes>
      <FooterComponent />
    </div>
  );
}

export default App;
