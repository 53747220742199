import axios from 'axios';
import { Button, Label, Modal, Spinner, TextInput, Table } from 'flowbite-react';
import { useState } from 'react';
import styled from 'styled-components';
import { BASE_URL } from './GetLink';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledTable = styled(Table)`
    .px-6 {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.6rem;
    }
`;

enum TransactionStatus {
    Processing = 'Đang xử lí',
    Authenticated = 'Chưa hoàn tiền',
    Refunded = 'Đã hoàn tiền'
}

const getTransactionStatus = (status: string) => {
    switch (status) {
        case '1':
            return TransactionStatus.Authenticated;
        case '2':
            return TransactionStatus.Refunded;
        default:
            return TransactionStatus.Processing;
    }
}

const Transaction = ({ openModal, setOpenModal }: any) => {
    const [loading, setLoading] = useState(false);
    const [phone, setPhone] = useState('');
    const [transactionData, setTransactionData] = useState([]);
    const [error, setError] = useState('');
    const [notFound, setNotFound] = useState(false);

    const fetchTransactionData = () => {
        setLoading(true);
        setError('');
        setNotFound(false);
        axios.get(`${BASE_URL}/rest.php`, { params: { phone: phone } })
            .then(response => {
                if (response.data.length === 0) {
                    setNotFound(true);
                }
                setTransactionData(response.data);
            })
            .catch(err => {
                setError('Failed to fetch transaction data.');
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const formatDate = (inputDateString: string) => {
        const year = parseInt(inputDateString.substr(0, 4));
        const month = parseInt(inputDateString.substr(4, 2)) - 1; // Months are zero-based
        const day = parseInt(inputDateString.substr(6, 2));

        const inputDate = new Date(year, month, day);

        const formattedDate = `${inputDate.getDate()}/${inputDate.getMonth() + 1}/${inputDate.getFullYear()}`;

        return formattedDate;
    };

    const formatNumber = (number: number) => {
        return number.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' });
    };

    return (
        <>
            <Modal show={openModal} onClose={() => {
                setTransactionData([]);
                setPhone('')
                setError('');
                setNotFound(false);
                setOpenModal(false)
            }}>
                <Modal.Header>Kiểm tra đơn hoàn</Modal.Header>
                <Modal.Body>
                    <Container>
                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="transactionId" value="Số điện thoại" />
                            </div>
                            <TextInput
                                id="transactionId"
                                type="text"
                                onChange={(e) => setPhone(e.target.value)}
                                placeholder="Nhập số điện thoại"
                                required
                            />
                        </div>

                        <Button onClick={fetchTransactionData}>
                            {loading ? <Spinner aria-label="Loading" /> : 'Check'}
                        </Button>

                        {error && <p>{error}</p>}
                        {notFound && <p>{`Không tìm thấy thông tin cho mã đơn hàng ${phone}. Vui lòng liên hệ admin để được hỗ trợ.`}</p>}

                        <div className="overflow-x-auto">
                            {transactionData.length > 0 && (<StyledTable>
                                <Table.Head>
                                    <Table.HeadCell>Ngày mua</Table.HeadCell>
                                    <Table.HeadCell>Tổng đơn</Table.HeadCell>
                                    <Table.HeadCell>Tiền hoàn</Table.HeadCell>
                                    <Table.HeadCell>Shopee</Table.HeadCell>
                                    <Table.HeadCell>Trạng thái</Table.HeadCell>
                                    <Table.HeadCell>Mã đơn hàng</Table.HeadCell>
                                </Table.Head>
                                <Table.Body className="divide-y">
                                    {transactionData.map((item: any, index) => (
                                        <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                            <Table.Cell>{formatDate(item.purchase_time)}</Table.Cell>
                                            <Table.Cell>{formatNumber(item.actual_amount)}</Table.Cell>
                                            <Table.Cell>{formatNumber(item.item_commission)}</Table.Cell>
                                            <Table.Cell>{item.order_status}</Table.Cell>
                                            <Table.Cell>{getTransactionStatus(item.status)}</Table.Cell>
                                            <Table.Cell>{item.transaction_id}</Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </StyledTable>)}
                        </div>
                    </Container>
                </Modal.Body>
            </Modal>


        </>
    );
}

export default Transaction;
