import { Blockquote } from 'flowbite-react';
import styled from 'styled-components';

const Container = styled.div`
    padding: 20px;
`;
function Introduce() {
    return (
        <Container>
            <Blockquote className="mb-3">
                <p className="text-xl font-semibold italic text-gray-900 dark:text-white">
                    " Tiền hoàn đến từ đâu? "
                </p>
            </Blockquote>
            <p className="mb-3 text-gray-500 dark:text-gray-400">
                Chắc các bạn đã nghe nhiều về tiếp thị liên kết đúng không nào? Tiếp thị liên kết là một hình thức tiếp thị trực tuyến, trong đó một doanh nghiệp trả tiền cho các nhà tiếp thị liên kết để quảng cáo sản phẩm của họ.
            </p>
            <p className="mb-3 text-gray-500 dark:text-gray-400">
                Ở các nền tảng thương mại điện tử như shopee hay lazada cũng khuyến khích cho hoạt động tiếp thị này.
            </p>
            <p className="mb-3 text-gray-500 dark:text-gray-400">
                Khi bạn sử dụng web tạo ra link tiếp thị liên kết và mua hàng, nghĩa là bên mình đã tiếp thị được 1 sản phẩm và nhận hoa hồng từ các nền tảng thương mại điện tử kể trên.
            </p>
            <p className="mb-3 text-gray-500 dark:text-gray-400">
                Vậy thay vì bạn mua sản phẩm trực tiếp và không nhận được gì, tại sao bạn không mua sản phẩm thông qua link tiếp thị liên kết của mình và nhận được hoa hồng?
            </p>
            <p className="mb-3 text-gray-500 dark:text-gray-400">
                Bên mình sẽ chi trả 80% hoa hồng cho bạn và giữ lại 20% làm chi phí phát triển.
            </p>
            <p className="mb-3 text-gray-500 dark:text-gray-400">
                Có thể nói chúng ta đang cộng sinh với nhau, bạn mua được sản phẩm với giá rẻ hơn, bên mình nhận được một phần hoa hồng.
            </p>

            <Blockquote className="mb-3">
                <p className="text-xl font-semibold italic text-gray-900 dark:text-white">
                    " Hoa hồng tiếp thị liên kết được tính như nào? "
                </p>
            </Blockquote>
            <p className="mb-3 text-gray-500 dark:text-gray-400">
                Hoa hồng phụ thuộc vào sản phẩm và chiến dịch, nên mình cũng không biết chắc được, bên mình trả về 80% hoa hồng các nền tảng thương mại điện tử trả cho mình. Bạn có thể tự mình kiểm tra hoa hồng bằng cách dán link sản phẩm trên app và bấm nút tạo link tiếp thị liên kết. Màn hình sẽ hiển thị link tiếp thị liên kết và số tiền hoàn về cho bạn.
            </p>

            <Blockquote className="mb-3">
                <p className="text-xl font-semibold italic text-gray-900 dark:text-white">
                    " Một vài hình ảnh ví dụ về hoa hồng shopee "
                </p>
            </Blockquote>
            <img src="/shopee1.png" className="mb-3" alt="Example 1"/>
            <img src="/shopee2.png" className="mb-3" alt="Example 2"/>
            <br></br>
            <br></br>
        </Container>
    );
}

export default Introduce;
